import React, { useState, useEffect, useRef } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  Box,
  Typography,
  TextField,
  Button,
  CircularProgress,
  Alert,
  Avatar,
  Grid,
  Paper,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";
import { db, storage } from "../../shared/firebase";
import {
  doc,
  getDoc,
  updateDoc,
  deleteDoc,
  collection,
  query,
  where,
  getDocs,
  arrayRemove,
} from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { useAuth } from "../../shared/useAuth";
import {
  reauthenticateWithCredential,
  EmailAuthProvider,
  verifyBeforeUpdateEmail,
} from "firebase/auth";
import ContainerBox from "../../shared/ContainerBox";
import { Helmet } from "react-helmet";
import { colors } from "../lib/theme";
import { ref as storageRef, deleteObject } from "firebase/storage";
import ConfirmDialog from "../../shared/ConfirmDialog";
import { useNavigate } from "react-router-dom";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import ImageUploader from '../../shared/ImageUploader';
import axios from 'axios';

const Profile = ({ config }) => {
  const { user, loading } = useAuth();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [error, setError] = useState(null);
  const [newEmail, setNewEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPasswordField, setShowPasswordField] = useState(false);
  const [profilePhotoURL, setProfilePhotoURL] = useState("");
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [isDeletingData, setIsDeletingData] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [subscription, setSubscription] = useState(null);
  const [isLoadingSubscription, setIsLoadingSubscription] = useState(false);
  const [subscriptionError, setSubscriptionError] = useState(null);
  const [stripeInstance, setStripeInstance] = useState(null);
  const [stripeCustomerId, setStripeCustomerId] = useState("");
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [hasDefaultPaymentMethod, setHasDefaultPaymentMethod] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const [subscriptionSource, setsubscriptionSource] = useState(null);
  const [subscriptionLevel, setSubscriptionLevel] = useState(null);
  const [billingAddress, setBillingAddress] = useState({
    line1: "",
    line2: "",
    city: "",
    state: "",
    postal_code: "",
    country: "",
  });
  const [checkoutError, setCheckoutError] = useState(null);

  useEffect(() => {
    const initializeStripe = async () => {
      if (config?.stripe?.publicKey) {
        const stripeInstance = await loadStripe(config.stripe.publicKey);
        setStripeInstance(stripeInstance);
      } else {
        console.error("Stripe public key is missing from the config");
      }
    };

    initializeStripe();
  }, [config]);

  useEffect(() => {
    if (user && stripeCustomerId) {
      fetchSubscriptionData();
    }
  }, [user, stripeCustomerId]);

  useEffect(() => {
    const fetchUserData = async () => {
      if (user) {
        setIsLoading(true);
        const userDoc = await getDoc(doc(db, "users", user.uid));
        const userData = userDoc.data();
        setFirstName(userData?.firstName || "");
        setLastName(userData?.lastName || "");
        setEmail(user.email);
        setProfilePhotoURL(userData?.profilePhotoURL || "");
        setStripeCustomerId(userData?.stripeCustomerId || "");
        setsubscriptionSource(userData?.subscriptionSource || 0);
        setSubscriptionLevel(userData?.subscriptionLevel || 0);
        setIsLoading(false);
      }
    };

    fetchUserData();
  }, [user]);

  useEffect(() => {
    const checkDefaultPaymentMethod = async () => {
      if (stripeCustomerId && config?.csrfToken) {
        try {
          const response = await fetch(
            `/api/user_subscription/default_payment_method/${stripeCustomerId}`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                "X-CSRF-Token": config.csrfToken,
              },
            }
          );
          const data = await response.json();
          setHasDefaultPaymentMethod(data.hasDefaultPaymentMethod);
        } catch (error) {
          console.error("Error checking default payment method:", error);
        }
      }
    };

    checkDefaultPaymentMethod();
  }, [stripeCustomerId, config?.csrfToken]);

  const fetchSubscriptionData = async () => {
    setIsLoadingSubscription(true);
    setSubscriptionError(null);
    try {
      if (!user?.uid) {
        setSubscription(null);
        return;
      }

      const response = await fetch(`/api/user_subscription/${stripeCustomerId}`);
      if (!response.ok) {
        throw new Error("Failed to fetch subscription data");
      }
      const data = await response.json();

      if (data.subscription) {
        setSubscription(data.subscription);
        // Update the user's subscription level in Firestore
        await updateDoc(doc(db, "users", user.uid), {
          subscriptionLevel: 1,
        });
        // Update the local state
        setSubscriptionLevel(1);
      } else {
        setSubscription(null);
      }
    } catch (error) {
      console.error("Error fetching subscription data:", error);

      // if (process.env.RAILS_ENV === "production") {
      setSubscriptionError("Failed to load subscription information.");
      // } else {
        // setSubscriptionError("A subscription exists for this email in prod mode. This error is expected on localhost.");
      // }
    } finally {
      setIsLoadingSubscription(false);
    }
  };

  const handleCancelSubscription = async () => {
    try {
      const response = await fetch(
        `/api/user_subscription/${subscription.id}/cancel`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "X-CSRF-Token": config.csrfToken,
          },
        }
      );
      if (!response.ok) {
        throw new Error("Failed to cancel subscription");
      }

      await updateDoc(doc(db, "users", user.uid), {
        subscriptionLevel: 0,
      });

      window.location.reload();
    } catch (error) {
      console.error("Error cancelling subscription:", error);
      setSubscriptionError("Failed to cancel subscription. Please try again.");
    } finally {
      setOpenCancelDialog(false);
    }
  };

  const handleSave = async () => {
    if (user) {
      setIsSaving(true);
      setShowSuccess(false);
      setError(null);
      try {
        await updateDoc(doc(db, "users", user.uid), {
          firstName: firstName.trim(),
          lastName: lastName.trim(),
        });
        setShowSuccess(true);
      } catch (error) {
        console.error("Error updating user profile:", error);
        setError("Failed to update profile. Please try again.");
      }
      setIsSaving(false);
    }
  };

  const handleEmailChange = async () => {
    if (user) {
      setIsSaving(true);
      setShowSuccess(false);
      setError(null);
      try {
        const credential = EmailAuthProvider.credential(user.email, password);
        await reauthenticateWithCredential(user, credential);

        await verifyBeforeUpdateEmail(user, newEmail);

        setShowPasswordField(false);
        setPassword("");
        setShowSuccess(true);
        setError(
          "A verification email has been sent to your new email address. Please check your inbox and follow the instructions to complete the email change."
        );
      } catch (error) {
        console.error("Error updating email:", error);
        console.error("Error code:", error.code);
        console.error("Error message:", error.message);

        switch (error.code) {
          case "auth/requires-recent-login":
            setError(
              "For security reasons, please log out and log back in before changing your email."
            );
            break;
          case "auth/invalid-email":
            setError(
              "The new email address is invalid. Please check and try again."
            );
            break;
          case "auth/email-already-in-use":
            setError("This email is already in use by another account.");
            break;
          case "auth/wrong-password":
            setError("Incorrect password. Please check and try again.");
            break;
          default:
            setError(`Failed to update email: ${error.message}`);
        }
      }
      setIsSaving(false);
    }
  };

  const handleUploadSuccess = (downloadURL) => {
    setProfilePhotoURL(downloadURL);
    setShowSuccess(true);
  };

  const handleUploadError = (errorMessage) => {
    setError(errorMessage);
  };

  const handleDeleteUserData = async () => {
    if (user) {
      setIsDeletingData(true);
      setError(null);
      setDeleteSuccess(false);
      try {
        const albumsQuery = query(
          collection(db, "albums"),
          where("userId", "==", user.uid)
        );
        const albumsSnapshot = await getDocs(albumsQuery);
        for (const albumDoc of albumsSnapshot.docs) {
          await deleteDoc(albumDoc.ref);
        }

        for (const albumDoc of albumsSnapshot.docs) {
          const albumData = albumDoc.data();

          if (albumData.tracks) {
            let tracksUpdated = false;
            const updatedTracks = albumData.tracks.map((track) => {
              if (track.audioSegments && Array.isArray(track.audioSegments)) {
                const originalLength = track.audioSegments.length;
                track.audioSegments = track.audioSegments.filter(
                  (segment) => segment.recordedBy !== user.uid
                );

                track.audioSegments.forEach(async (segment) => {
                  if (segment.audioUrl) {
                    const audioRef = storageRef(storage, segment.audioUrl);
                    try {
                      await deleteObject(audioRef);
                    } catch (error) {
                      console.error("Error deleting audio file:", error);
                    }
                  }
                });

                if (track.audioSegments.length !== originalLength) {
                  tracksUpdated = true;
                }
              }
              return track;
            });

            if (tracksUpdated) {
              try {
                await updateDoc(albumDoc.ref, { tracks: updatedTracks });
              } catch (error) {
                console.error("Error updating album:", albumDoc.id, error);
                console.error("Error details:", error.code, error.message);
                setError((prev) =>
                  prev
                    ? `${prev}\nFailed to update album ${albumDoc.id}: ${error.message}`
                    : `Failed to update album ${albumDoc.id}: ${error.message}`
                );
              }
            }
          }
        }

        const sharedAlbumsQuery = query(
          collection(db, "albums"),
          where("sharedWith", "array-contains", user.uid)
        );
        const sharedAlbumsSnapshot = await getDocs(sharedAlbumsQuery);
        for (const albumDoc of sharedAlbumsSnapshot.docs) {
          await updateDoc(albumDoc.ref, {
            sharedWith: arrayRemove(user.uid),
          });
        }

        const invitedAlbumsQuery = query(
          collection(db, "albums"),
          where("invitedTo", "array-contains", user.email)
        );
        const invitedAlbumsSnapshot = await getDocs(invitedAlbumsQuery);
        for (const albumDoc of invitedAlbumsSnapshot.docs) {
          await updateDoc(albumDoc.ref, {
            invitedTo: arrayRemove(user.email),
          });
        }

        await updateDoc(doc(db, "users", user.uid), {
          firstName: "",
          lastName: "",
          profilePhotoURL: "",
        });

        setDeleteSuccess(true);
      } catch (error) {
        console.error("Error deleting user data:", error);
        if (error.code === "permission-denied") {
          setError(
            "You don't have permission to delete this data. This might be due to security rules or network issues. Please try logging out and back in, or contact support if the problem persists."
          );
        } else {
          setError(`Failed to delete user data: ${error.message}`);
        }
      } finally {
        setIsDeletingData(false);
        setOpenDeleteDialog(false);
      }
    }
  };

  const handleSubscribe = async () => {
    if (!stripe || !elements) {
      return;
    }

    setIsSaving(true);
    setError(null);
    setCheckoutError(null);

    try {
      let paymentMethodId;

      if (!hasDefaultPaymentMethod) {
        const cardElement = elements.getElement(CardElement);
        const { error, paymentMethod } = await stripe.createPaymentMethod({
          type: "card",
          card: cardElement,
          billing_details: {
            address: billingAddress,
            name: `${firstName} ${lastName}`,
          },
        });

        if (error) {
          setCheckoutError(error.message);
          return;
        }

        paymentMethodId = paymentMethod.id;
      }

      const response = await fetch("/api/user_subscription/create", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": config.csrfToken,
        },
        body: JSON.stringify({
          stripe_customer_id: stripeCustomerId,
          email: email,
          firstName: firstName,
          userId: user.uid,
          lastName: lastName,
          payment_method_id: paymentMethodId,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to create subscription");
      }

      const data = await response.json();

      // Remove all scheduled jobs for this user
      await axios.delete('/api/jobs/remove_all', { data: { user_id: user.uid } });

      window.location.reload();
    } catch (error) {
      console.error("Error creating subscription:", error);
      setCheckoutError(error.message || "Failed to create subscription. Please try again.");
    } finally {
      setIsSaving(false);
    }
  };

  if (loading || isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "200px",
        }}
      >
        <CircularProgress sx={{ color: colors.gold }} />
      </Box>
    );
  }

  return (
    <ContainerBox>
      <Helmet>
        <title>Profile - Golden Record</title>
        <meta name="description" content="Edit your Golden Record profile" />
      </Helmet>
      <Typography variant="h3" sx={{ mb: 4 }}>
        Your Profile
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: 3 }}>
            <ImageUploader
              user={user}
              onUploadSuccess={handleUploadSuccess}
              onUploadError={handleUploadError}
            >
              <Box
                sx={{
                  mb: 3,
                  cursor: "pointer",
                  "&:hover": { opacity: 0.8 },
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Avatar
                  src={profilePhotoURL}
                  alt={`${firstName} ${lastName}`}
                  sx={{ width: 120, height: 120 }}
                />
                <Typography
                  variant="caption"
                  sx={{ mt: 1, display: "block", textAlign: "center" }}
                >
                  Click to change profile photo
                </Typography>
              </Box>
            </ImageUploader>
            <TextField
              fullWidth
              label="First Name"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              margin="normal"
            />
            <TextField
              fullWidth
              label="Last Name"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              margin="normal"
            />
            {showSuccess && (
              <Alert severity="success" sx={{ mt: 2, mb: 2, width: "100%" }}>
                Profile updated successfully!
              </Alert>
            )}
            {error && (
              <Alert severity="error" sx={{ mt: 2, mb: 2, width: "100%" }}>
                {error}
              </Alert>
            )}
            <Button
              variant="contained"
              onClick={handleSave}
              disabled={isSaving}
              sx={{ mt: 2, mb: 3, display: "block" }}
            >
              {isSaving ? "Saving..." : "Save Changes"}
            </Button>
            <TextField
              fullWidth
              label="Current Email"
              value={email}
              disabled
              margin="normal"
            />
            <TextField
              fullWidth
              label="New Email"
              value={newEmail}
              onChange={(e) => setNewEmail(e.target.value)}
              margin="normal"
            />
            {showPasswordField && (
              <TextField
                fullWidth
                label="Password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                margin="normal"
              />
            )}
            <Button
              variant="emphasized"
              onClick={() => {
                if (showPasswordField) {
                  handleEmailChange();
                } else {
                  setShowPasswordField(true);
                }
              }}
              disabled={isSaving || (showPasswordField && !password)}
              sx={{ mt: 2, display: "block" }}
            >
              {isSaving
                ? "Updating..."
                : showPasswordField
                ? "Confirm Email Change"
                : "Update Email"}
            </Button>
          </Paper>

          <Box sx={{ mt: 10, maxWidth: 400, width: "100%" }}>
            <Typography variant="h5" sx={{ mb: 1, color: "error.main" }}>
              Danger Zone
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
              Deleting your data is irreversible and will remove all your
              albums, tracks, and recordings from our systems. This will also
              remove recordings you have made in any shared albums. You'll be
              removed from any album that is shared with you, as well as any
              album you've been invited to.
              <strong>
                This does not cancel your subscription. This action cannot be
                undone.
              </strong>
            </Typography>
            {deleteSuccess && (
              <Alert severity="success" sx={{ mt: 2, mb: 2, width: "100%" }}>
                All your data has been successfully deleted.
              </Alert>
            )}
            {error && (
              <Alert severity="error" sx={{ mt: 2, mb: 2, width: "100%" }}>
                {error}
              </Alert>
            )}
            <Button
              variant="contained"
              color="error"
              onClick={() => setOpenDeleteDialog(true)}
              sx={{ mt: 2, display: "block" }}
            >
              Delete All My Data
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{ p: 3 }}>
            <Typography variant="h5" sx={{ mb: 2 }}>
              Subscription Management
            </Typography>
            {isLoadingSubscription ? (
              <CircularProgress sx={{ color: colors.gold }} />
            ) : subscriptionError ? (
              <Alert severity="error">{subscriptionError}</Alert>
            ) : subscriptionSource === 1 ? (
              <Alert severity="info">
                Your subscription is managed through the iPhone app. Please use the app to manage your subscription.
              </Alert>
            ) : subscriptionLevel === 1 && subscription ? (
              <>
                <Typography variant="body1" sx={{ mb: 1 }}>
                  Status: {subscription.status}
                </Typography>
                <Typography variant="body1" sx={{ mb: 2 }}>
                  Next billing date:{" "}
                  {new Date(
                    subscription.current_period_end * 1000
                  ).toLocaleDateString()}
                </Typography>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => setOpenCancelDialog(true)}
                >
                  Cancel Subscription
                </Button>
              </>
            ) : (
              <>
                <Typography variant="body1">
                  No active subscription found.
                </Typography>
                <Typography variant="h6" sx={{ mt: 4, mb: 1 }}>
                  Subscribe to get access to all features
                </Typography>
                <ul>
                  <li>- Share albums with others</li>
                  <li>- Make albums public</li>
                  <li>- Increased storage</li>
                  <li>- Priority customer support</li>
                  <li>- Unlimited albums</li>
                  <li>- Unlimited tracks</li>
                  <li>- Unlimited recordings</li>
                </ul>
                <Typography variant="body1" sx={{ mt: 2, fontWeight: "bold" }}>
                  Billed annually at $49
                </Typography>
                {!hasDefaultPaymentMethod && (
                  <>
                    <Typography variant="h6" sx={{ mt: 3, mb: 2 }}>
                      Billing Address
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          label="Address Line 1"
                          value={billingAddress.line1}
                          onChange={(e) => setBillingAddress({ ...billingAddress, line1: e.target.value })}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          label="Address Line 2"
                          value={billingAddress.line2}
                          onChange={(e) => setBillingAddress({ ...billingAddress, line2: e.target.value })}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          label="City"
                          value={billingAddress.city}
                          onChange={(e) => setBillingAddress({ ...billingAddress, city: e.target.value })}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          label="State/Province"
                          value={billingAddress.state}
                          onChange={(e) => setBillingAddress({ ...billingAddress, state: e.target.value })}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          label="Postal Code"
                          value={billingAddress.postal_code}
                          onChange={(e) => setBillingAddress({ ...billingAddress, postal_code: e.target.value })}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                          <InputLabel id="country-select-label">Country</InputLabel>
                          <Select
                            labelId="country-select-label"
                            id="country-select"
                            value={billingAddress.country}
                            label="Country"
                            onChange={(e) => setBillingAddress({ ...billingAddress, country: e.target.value })}
                          >
                            <MenuItem value="US">United States</MenuItem>
                            <MenuItem value="UK">United Kingdom</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                    <Box
                      sx={{
                        mt: 3,
                        border: `1px solid ${colors.lighterGray}`,
                        borderRadius: "4px",
                        padding: "15px",
                        backgroundColor: colors.white,
                      }}
                    >
                      <CardElement
                        options={{
                          style: {
                            base: {
                              fontSize: "16px",
                              color: colors.navy,
                              "::placeholder": {
                                color: "#aab7c4",
                              },
                            },
                          },
                        }}
                      />
                    </Box>
                  </>
                )}
                {checkoutError && (
                  <Alert severity="error" sx={{ mt: 2, mb: 2 }}>
                    {checkoutError}
                  </Alert>
                )}
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubscribe}
                  disabled={isSaving}
                  sx={{ mt: 2 }}
                >
                  {isSaving ? "Processing..." : "Subscribe"}
                </Button>
              </>
            )}
          </Paper>
        </Grid>
      </Grid>

      <ConfirmDialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        onConfirm={handleDeleteUserData}
        title="Delete All Your Data?"
        content="This action will permanently delete all your albums, tracks, and recordings. This does not cancel your subscription. This cannot be undone. Are you sure you want to proceed?"
        confirmText={isDeletingData ? "Deleting..." : "Delete All Data"}
      />

      <ConfirmDialog
        open={openCancelDialog}
        onClose={() => setOpenCancelDialog(false)}
        onConfirm={handleCancelSubscription}
        title="Cancel Subscription?"
        content="Are you sure you want to cancel your subscription? This action cannot be undone."
        confirmText="Cancel Subscription"
      />
    </ContainerBox>
  );
};

export default Profile;
